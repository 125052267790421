// components/Icons.js
import React from "react";
import CustomSpinnerOtp from "../CustomSpinnerOtp/CustomSpinnerOtp";

export const IconSuccess = () => (
  <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#B7EB8F]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6 12.37L9.76 16.12L18 7.88"
        stroke="#2D2E2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

// Para la flecha hacia arriba, representando entradas.
export const UpArrowIn = () => (
  <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#B7EB8F]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M16.24 7.76001L7.76001 16.24M16.24 7.76001H11.15M16.24 7.76001V12.85"
        stroke="#2D2E2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

// Para la flecha hacia abajo, representando salidas.
export const DownArrowOut = () => (
  <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#B7EB8F]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M9.7 20.2999L20.3 9.69995M9.7 20.2999H16.0625M9.7 20.2999V13.9375"
        stroke="#2D2E2F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

export const BatchSuccessIcon = () => (
  <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#FFF133]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M13.0369 1.34631L3.0569 11.3463M13.0369 1.34631H9.0569M13.0369 1.34631V5.34631M13.0569 10.218L11.5591 8.71226M13.0569 10.218L12.0593 12.2115M13.0569 10.218C13.0569 10.218 10.5986 10.6021 9.05689 11.218C7.19105 11.9635 5.55712 13.6537 5.55712 13.6537M5.24634 1.55638L6.56335 3.22259M5.24634 1.55638L3.14837 2.30983M5.24634 1.55638C5.24634 1.55638 4.57277 3.95159 3.77786 5.40911C2.81582 7.17308 0.943359 8.59447 0.943359 8.59447"
        stroke="#2D2E2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

export const IconError = () => (
  <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#FAA6A6]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.91429 4.91431L11.0857 11.0857"
        stroke="#2D2E2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.0857 4.91431L4.91429 11.0857"
        stroke="#2D2E2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

export const BatchErrorIcon = () => (
  <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#F76A6A]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <path
        d="M13.0364 1.34631L3.05641 11.3463M13.0364 1.34631H9.05641M13.0364 1.34631V5.34631M13.0564 10.218L11.5586 8.71226M13.0564 10.218L12.0589 12.2115M13.0564 10.218C13.0564 10.218 10.5981 10.6021 9.0564 11.218C7.19057 11.9635 5.55663 13.6537 5.55663 13.6537M5.24585 1.55638L6.56287 3.22259M5.24585 1.55638L3.14788 2.30983M5.24585 1.55638C5.24585 1.55638 4.57228 3.95159 3.77737 5.40911C2.81533 7.17308 0.942871 8.59447 0.942871 8.59447"
        stroke="#2D2E2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);

export const SendEmailIcon = () => (
  <div className="bg-[#E6F7FF] w-[30px] h-[30px] rounded-full flex justify-center items-center   ">

    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="fullInventory/Email">
        <path id="Vector" d="M13.95 3H4.05C2.64167 3 1.5 4.14167 1.5 5.55V12.45C1.5 13.8583 2.64167 15 4.05 15H13.95C15.3583 15 16.5 13.8583 16.5 12.45V5.55C16.5 4.14167 15.3583 3 13.95 3Z" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path id="Vector_2" d="M4.5 6.75L8.13 9.1275C8.38404 9.30838 8.68814 9.40557 9 9.40557C9.31186 9.40557 9.61596 9.30838 9.87 9.1275L13.5 6.75" stroke="#2D2E2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </g>
    </svg>

  </div>
);

export const DownloadIcon = () => (
  <div className="w-[30px] h-[30px] bg-[#E6F7FF] rounded-full flex justify-center items-center">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="DOWNLOAD-MINI-ICON">
        <path id="Vector" d="M7.99998 11.2L3.99998 7.19998L5.11998 6.03998L7.19998 8.11998V1.59998H8.79998V8.11998L10.88 6.03998L12 7.19998L7.99998 11.2ZM3.19998 14.4C2.75998 14.4 2.38331 14.2433 2.06998 13.93C1.75664 13.6166 1.59998 13.24 1.59998 12.8V10.4H3.19998V12.8H12.8V10.4H14.4V12.8C14.4 13.24 14.2433 13.6166 13.93 13.93C13.6166 14.2433 13.24 14.4 12.8 14.4H3.19998Z" fill="#2D2E2F" />
      </g>
    </svg>
  </div>
);

export const IconInfo = () => <span>❌</span>;

export const IconWarning = () => (
  <div className="w-[30px] h-[30px] p-1 rounded-full flex justify-center items-center bg-[#FFD595]">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <path
        d="M19.0002 11.8856V21.7293M19.0017 25.9481H19.0004M16.2971 7.97933L5.51584 26.7293C5.2417 27.2042 5.09731 27.7427 5.09717 28.291C5.09703 28.8393 5.24114 29.378 5.51504 29.8529C5.78893 30.3279 6.18297 30.7225 6.65759 30.997C7.1322 31.2715 7.67068 31.4163 8.21896 31.4168H29.7815C30.3297 31.4163 30.8682 31.2715 31.3428 30.997C31.8175 30.7225 32.2115 30.3279 32.4854 29.8529C32.7593 29.378 32.9034 28.8393 32.9033 28.291C32.9031 27.7427 32.7587 27.2042 32.4846 26.7293L21.7033 7.97933C21.4286 7.50577 21.0343 7.11268 20.5599 6.83944C20.0855 6.56619 19.5477 6.42236 19.0002 6.42236C18.4527 6.42236 17.9149 6.56619 17.4405 6.83944C16.9661 7.11268 16.5718 7.50577 16.2971 7.97933Z"
        stroke="#2D2E2F"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
);




export const IconLoading = () => (
  <CustomSpinnerOtp className="min-w-[30px] min-h-[30px]" />
);

export const Button = () => (
  <div className="w-[30px] h-[30px] rounded-full flex justify-center items-center bg-[#FAA6A6]"></div>
);

export const toastConfig = {
  sendEmailIcon: <SendEmailIcon />,
  iconSuccess: <IconSuccess />,
  upArrowIn: <UpArrowIn />,
  downArrowOut: <DownArrowOut />,
  batchSuccessIcon: <BatchSuccessIcon />,
  iconError: <IconError />,
  batchErrorIcon: <BatchErrorIcon />,
  iconInfo: <IconInfo />,
  iconWarning: <IconWarning />,
  iconLoading: <IconLoading />,
  downloadIcon: <DownloadIcon/>
};

