import {
  useToastDispatch,
  useToastState,
} from "@/Context/ToastContext/ToastContext";
import { toastConfig } from "@shared/ToastContainer/Icons";
import { ToastConfig, Toast } from "@/Context/ToastContext/types";

const defaultToastConfig: ToastConfig = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: true,
  theme: "light",
  isHovered: true,
};

const generateId = (): string => Math.random().toString(36).substring(2, 9);

const useToast = (defaultDelay: number = 3000) => {
  const { addToast, deleteToast, updateToast } = useToastDispatch();
  const [toasts, setToasts] = useToastState();

  const addNewToast = (message: string | JSX.Element, config: ToastConfig,) => {
    const id = generateId();

    const iconMap: Record<string, JSX.Element | null> = {
      success: config.iconSuccess || toastConfig.iconSuccess,
      error: config.iconError || toastConfig.iconError,
      info: toastConfig.iconInfo,
      warning: toastConfig.iconWarning,
      loading: toastConfig.iconLoading,
    };

    const icon = iconMap[config.type || "info"];
    const finalConfig: Toast = {
      ...defaultToastConfig,
      ...config,
      message,
      id: config?.id || id,
      icon,
    };

    // Filtra las toasts que no son de tipo 'loading'
    // const nonLoadingToasts = toasts.filter(
    //   (toast) => toast.message === message
    // );
    // console.log(nonLoadingToasts.length);
    // if (nonLoadingToasts.length) {
    //   return;
    // }

    addToast({
      id: finalConfig.id,
      message: finalConfig.message,
      icon: finalConfig.icon,
      type: finalConfig.type || "info",
      show: true,
      reretry: 0,
      autoClose: finalConfig.autoClose,
      isHovered: finalConfig.isHovered,
      height: finalConfig.height,
      ...finalConfig,
    });
  };

  const info = (message: string, config?: ToastConfig) =>
    addNewToast(message, { ...config, type: "info", status: 2 }, );
  const success = (message: string | JSX.Element, config?: ToastConfig) =>
    addNewToast(message, { ...config, type: "success", status: 2 });
  const warning = (message: string, config?: ToastConfig) =>
    addNewToast(message, { ...config, type: "warning", status: 2 });
  const error = (message: string, config?: ToastConfig) =>
    addNewToast(message, { ...config, type: "error", status: 2 });

  const retryPromise = async (
    retryPromiseFn: any, // Función que devuelve la promesa con la URI de reintento
    retryToastId: string, // ID del toast para actualizar
    config: ToastConfig,
    message: any
  ) => {
    try {
      updateToast({
        ...config,
        id: retryToastId,
        message: message.loading,
        type: "loading",
        icon: toastConfig.iconLoading,
        status: 1,
        action: {},
        progress: 0,
        autoClose: config.autoClose,
        reretry: 1,
      });

      const data = await retryPromiseFn();
      updateToast({
        id: retryToastId,
        message: message?.success,
        status: 0,
        type: "success",
        icon: config.iconSuccess,
        show: true,
        reretry: 1,
      });
      return data;
    } catch (e) {
     

      updateToast({
        id: retryToastId,
        message: message?.error,
        status: 0,
        type: "error",
        icon: config.iconError,
        show: true,
        action: {
          text: "Reintentar",
          onClick: () =>
            retryPromise(retryPromiseFn, retryToastId, config, message), // Reintenta con la nueva URI
        },
        reretry: 1,
      });
      return e;
    }
  };

  const promise = async <T>(
    promiseFn: any, // Función que devuelve la promesa original
    {
      loading,
      success,
      error,
    }: {
      loading: string;
      success: string;
      error: string;
    },
    config: ToastConfig
  ): Promise<T> => {
    const loadingToastId = generateId();
    const toastToRetry = toasts.find(
      (toast) => toast?.type !== "loading" && toast?.status === 0
    )?.id;

    if (toastToRetry) {
     

      deleteToast({ id: toastToRetry });
    }

    addNewToast(loading, {
      ...config,
      id: loadingToastId,
      type: "loading",
      status: 1,
      reretry: 0,
    });

    try {
      const data = await promiseFn();
      updateToast({
        id: loadingToastId,
        message: success,
        status: 0,
        type: "success",
        icon: config.iconSuccess,
        show: true,
        reretry: 0,
      });
      return data;
    } catch (err) {
      

      updateToast({
        id: loadingToastId,
        message: error ?? err,
        status: 0,
        type: "error",
        icon: config.iconError,
        show: true,
        reretry: 0,
        action: {
          text: "Reintentar",
          onClick: () =>
            retryPromise(promiseFn, loadingToastId, config, {
              loading,
              success,
              error: error ?? err,
            }), // Reintenta con la nueva URI
        },
      });
      return err;
    }

    // return promise
    //   .then((data) => {
    //     const successMessage =
    //       typeof success === "function" ? success(data) : success;
    //     updateToast({
    //       id: loadingToastId,
    //       message: successMessage,
    //       status: 0,
    //       type: "success",
    //       icon: config.iconSuccess,
    //       show: true,
    //     });
    //     return data;
    //   })
    //   .catch((err) => {
    //     const errorMessage = typeof error === "function" ? error(err) : error;
    //     updateToast({
    //       id: loadingToastId,
    //       message: errorMessage,
    //       status: 0,
    //       type: "error",
    //       icon: config.iconError,
    //       show: true,
    //       // action: {
    //       //   text: "Reintentar",
    //       //   onClick: () => handleRetry(promise, loadingToastId), // Reintenta con la nueva URI
    //       // },
    //     });
    //     throw err;
    //   });
  };

  return { info, success, warning, error, promise };
};

export default useToast;

// const handleRetry = async (
//   retryPromiseFn: any, // Función que devuelve la promesa con la URI de reintento
//   retryToastId: string // ID del toast para actualizar
// ) => {
//   updateToast({
//     ...config,
//     id: retryToastId,
//     message: loading,
//     type: "loading",
//     icon: toastConfig.iconLoading,
//     status: 1,
//     action: {},
//     progress: 0,
//     autoClose: config.autoClose,
//     reretry: 1,
//   });

//   return retryPromiseFn
//     .then((data) => {
//       const successMessage =
//         typeof success === "function" ? success(data) : success;
//       updateToast({
//         id: retryToastId,
//         message: successMessage,
//         status: 0,
//         type: "success",
//         icon: config.iconSuccess,
//         reretry: 0,
//       });
//       return data;
//     })
//     .catch((err) => {
//       const errorMessage = typeof error === "function" ? error(err) : error;
//       updateToast({
//         id: retryToastId,
//         message: errorMessage,
//         status: 0,
//         type: "error",
//         icon: config.iconError,
//         show: true,
//         action: {
//           text: "Reintentar",
//           onClick: () => handleRetry(retryPromiseFn, loadingToastId), // Reintenta con la nueva URI
//         },
//       });
//       throw err;
//     });

//   // try {
//   //   const data = await retryPromiseFn(); // Llama a la promesa de reintento
//   //   const successMessage =
//   //     typeof success === "function" ? success(data) : success;
//   //   updateToast({
//   //     id: retryToastId,
//   //     message: successMessage,
//   //     status: 0,
//   //     type: "success",
//   //     icon: config.iconSuccess,
//   //     reretry: 0,
//   //   });
//   //   return data;
//   // } catch (err) {
//   //   const errorMessage = typeof error === "function" ? error(err) : error;
//   //   updateToast({
//   //     id: retryToastId,
//   //     message: errorMessage,
//   //     status: 0,
//   //     type: "error",
//   //     icon: config.iconError,
//   //     show: true,
//   //     action: {
//   //       text: "Reintentar",
//   //       onClick: () => handleRetry(retryPromiseFn, loadingToastId), // Reintenta con la nueva URI
//   //     },
//   //   });
//   //   throw err;
//   // }
// };

// Guarda la función original para el reintento
// const retryPromiseFn = () =>
//   fetch("https://httpbin.org/delay/5") // URI de reintento
//     .then((response) => {
//       if (!response.ok) throw new Error(`Error: ${response.status}`);
//       return response.json();
//     });
