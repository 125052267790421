import {
  useToastDispatch,
  useToastState,
} from "@/Context/ToastContext/ToastContext";
import { Toast as ToastType } from "@/Context/ToastContext/types";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import "./Toast.css";

const Toast = ({ toast }: { toast: ToastType; style?: React.CSSProperties;}) => {
  // const [toasts, setToasts] = useToastState();
  const { type, message, autoClose, theme, hideProgressBar, position, id } = toast;

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const timeoutRef = useRef(null); // Ref para almacenar el timeout
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [progress, setProgress] = useState(100); // Ref para almacenar el timeout
  const intervalRef = useRef(null); // Maneja el intervalo del progreso
  const { addToast, deleteToast, updateToast } = useToastDispatch();



  // Definir variantes de animación para cada posición
  const toastVariants = {
    "top-left": {
      hidden: { x: "-100%" },
      visible: {
        x: isHovered ? "-10%" : isLoading ? "-82%" : "-10%",
      },
      exit: {
        x: "-100%",
      },
    },
    "top-center": {
      hidden: { y: "-100%" },
      visible: {
        y: isHovered ? 0 : isLoading && status === 1 ? "82%" : 0,
      },
      exit: {
        y: "-100%",
      },
    },
    "top-right": {
      hidden: { x: "200%" },
      visible: {
        x: isHovered ? "10%" : isLoading ? "82%" : "10%",
      },
      exit: {
        x: "200%",
      },
    },
    "bottom-left": {
      hidden: { x: "-100%" },
      visible: {
        x: isHovered ? "-10%" : isLoading ? "-82%" : "-10%",
      },
      exit: {
        x: "-100%",
      },
    },
    "bottom-center": {
      hidden: { y: "100%" },
      visible: {
        y: isHovered ? 0 : isLoading && status === 1 ? "82%" : 0,
      },
      exit: {
        y: "100%",
      },
    },
    "bottom-right": {
      hidden: { x: "100%" },
      visible: {
        x: isHovered ? "10%" : isLoading ? "82%" : "10%",
      },
      exit: {
        x: "100%",
      },
    },
  };

  // Función para manejar el hover
  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current!);
    updateToast({ ...toast, isHovered: true });
  };

  const handleMouseLeave = () => {
    updateToast({ ...toast, isHovered: false });
    startHideTimeout();
  };


  // Inicia el temporizador del toast
  const startHideTimeout = () => {
    clearTimeout(timeoutRef.current!);
    timeoutRef.current = setTimeout(() => {
      deleteToast({ id });
    }, autoClose!);
  };

  useEffect(() => {
    if (!toast.isHovered) {
      startHideTimeout();
    }
    return () => clearTimeout(timeoutRef.current!);
  }, [toast.isHovered]);


  // const startHideTimeout = () => {
  //   if (toast.reretry && toast.reretry === 1) {
  //     return;
  //   }
  //   setTimeout(() => {
  //     deleteToast({ id: id });
  //   }, autoClose);
  // };


  const clearHideTimeout = () => {
    // Limpia el temporizador cuando sea necesario (por ejemplo, al hacer hover)
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null; // Restablece la referencia
    }
  };


  useEffect(() => {
    // if (toast.status && toast.status === 0 && type === "success") {
    //   startHideTimeout();
    //   return;
    // }
    setStatus(toast?.status);

    if (
      autoClose > 0 &&
      // type === "loading" &&
      toast?.progress === 100 &&
      (toast.status === 1 || toast.status === 0)
    ) {
      setIsLoading(true);
    }
    // if (autoClose > 0 && type === "error" && toast?.progress === 100) {
    //   setIsLoading(true);
    // }

    if (
      toast.status === 2 &&
      autoClose > 0 &&
      type !== "loading" &&
      toast?.progress === 100
    ) {
      deleteToast({ id: id });
    }

    return () => {
      clearHideTimeout();
    };
  }, [toast]);


  const onClose = (id: string) => {
    deleteToast({ id: id });
  };

  const handleActionClick = () => {
    if (toast.action && toast.action.onClick) {
      toast.action.onClick();
    }
  };

  return (
    <motion.div
      ref={wrapperRef}
      variants={toastVariants[position]}
      initial="hidden"
      animate="visible"
      exit="exit"
      style={{
        height: toast.height || "auto", // Aplicar la altura personalizada o usar "auto"
        marginTop: toast.height || "0", // Agregar margen igual a la altura
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        // if (toast.progress === 100) {
        setIsHovered(!isHovered);
        // }
      }}
      // onMouseEnter={() => {
      //   setIsHovered(true);
      //   // clearHideTimeout();
      // }}
      // onMouseLeave={() => {
      //   setIsHovered(false);
      //   // startHideTimeout();
      // }}
      className="2xl:w-[422px] xl:w-[422px] tablet:w-[422px] mobile:w-[322px] lg:w-[422px] mobile:w-[322px] 2xl:h-[92px] xl:h-[92px] xl:h-[92px] lg:h-[92px] tablet:h-[92px] mobile:h-[82px]  flex flex-col bg-[#FFFFFF] dark:bg-[var(--background-Component-module-dark)] shadow darK:shadow-2xl rounded-lg relative group cursor-pointer z-50"
      role="alert"
      transition={{ duration: 0.5 }}
    >
      <div
        className={`w-full min-h-[92px] flex ${position === "bottom-left" || position === "top-left"
          ? "flex-row-reverse"
          : "flex"
          } items-center gap-4 px-4 py-2`}
      >
        {toast.icon && typeof toast.icon === "string" ? (
          <div className="w-[64px] h-[56px] flex justify-center items-center">
            <div dangerouslySetInnerHTML={{ __html: toast.icon }} />
          </div>
        ) : (
          <div className="w-[64px] h-[56px] flex justify-center items-center">
            {toast.icon}
          </div>
        )}
        <div
          className={`min-w-[240px] max-w-[318px]  leading-[16.94px] ${isLoading && !isHovered ? "opacity-0" : "opacity-100"
            } flex items-center gap-2 pr-12 transition-all duration-500`}
        >
          <p className="text-[#2D2E2F] dark:text-[#FFFFFF] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] mobile:text-[12px]">{message}</p>
          {toast?.action && toast?.action?.onClick && (
            <button
              className="min-w-[75px] h-[24px] border border-[#818282] p-2 flex justify-center items-center rounded-[24px]"
              // onClick={handleActionClick}
              onClick={() => {
                handleActionClick();
                setIsLoading(true);
                setIsHovered(false);
              }}
            >
              <div className="text-[#818282] text-[12px] mobile:text-[10px]">
                {toast?.action?.text}
              </div>
            </button>
          )}
        </div>
        {((isLoading && toast.status === 0) || type !== "loading") && (

         
            <div
              className={`w-[28px] h-[28px] absolute ${position === "bottom-left" || position === "top-left"
                ? "-right-1 -top-1 "
                : "-left-1 -top-1 "
                } justify-center items-center mobile:block mobile:bg-white mobile:dark:border mobile:dark:bg-[#2d2e2f] mobile:hover:bg-white   
                  mobile:flex mobile:rounded-full 2xl:hidden xl:hidden lg:hidden tablet:hidden group-hover:flex group-hover:bg-[#fff] dark:group-hover:bg-[#2D2E2F] group-hover:shadow group-hover:rounded-full cursor-pointer  transition-all duration-300 ease-in-out 
                 `}
            

              onClick={() => onClose(id)}
              // style={{
              //   animation: 'fade-bg 0.6s forwards',
              // }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className={`mobile:text-[#c0c0c1] mobile:hover:bg-[#2d2e2f] mobile:hover:w-full mobile:hover:h-full mobile:hover:px-1 mobile:hover:rounded-full 
                            2xl:text-[#c0c0c1] xl:text-[#c0c0c1] lg:text-[#c0c0c1] tablet:text-[#c0c0c1]  mobile:dark:text-white  mobile:hover:text-white 2xl:hover:text-white xl:hover:text-white lg:hover:text-white tablet:hover:text-white 
                            2xl:hover:bg-black xl:hover:bg-black lg:hover:bg-black tablet:hover:bg-black 2xl:hover:rounded-full xl:hover:rounded-full lg:hover:rounded-full tablet:hover:rounded-full 
                            2xl:hover:w-full xl:hover:w-full lg:hover:w-full tablet::hover:w-full tablet:hover:w-full 2xl:hover:h-full xl:hover:h-full lg:hover:h-full tablet:hover:h-full 
                            2xl:hover:px-1 xl:hover:px-1 lg:hover:px-1 tablet:hover:px-1  transition-all duration-300 ease-in-out 
                 `}
              >
                <path
                  d="M5 5L13 13"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13 5L5 13"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          
        )}
      </div>
      <div
        className={`min-h-[4px] bg-[#C0C0C1] mobile:absolute mobile:bottom-[0.1px]  mobile:rounded-bl-lg absolute bottom-0 rounded-bl-lg ${!hideProgressBar ? "bg-transparent" : "bg-[#C0C0C1]"
          }
        ${(toast.reretry === 1 && !isHovered) ||
            (isLoading && toast.progress === 100)
            ? "opacity-0"
            : "opacity-100"
          } transition-all`}
        style={{ width: `${toast?.progress}%` }}
      ></div>

    </motion.div>
  );
};
export default Toast;
