import { useTheme } from "@/Context/UseContext/ThemeContext";

export const ArrowHide = ({
  className,
  stroke,
  onClick,
}: {
  className?: any;
  stroke?: string;
  onClick?: () => void;
}) => {

    const { theme, toggleDarkMode } = useTheme(); // Usa el contexto


  return (
    <svg
      onClick={onClick}
      className={className}
      width="14"
      height="8"
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 8L13 1"
        // stroke={stroke}
        stroke={
            theme === "dark"
              ? stroke
              : stroke
          }
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
